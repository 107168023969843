<template>
  <div class="items-container">
    <v-row>
      <v-col
        cols="12"
        md="4">
        <SearchBox
          v-model="query.search"
          :disabled="loading"
          @search="getItems(true)" />
      </v-col>
      <v-col
        cols="12"
        md="auto">
        <status-filters
          v-model="query.type"
          :items="types"
          label="ประเภท"
          :disabled="loading" />
      </v-col>
      <v-spacer />
      <v-col
        cols="12"
        md="auto">
        <v-btn
          color="primary"
          outlined
          depressed
          block
          :ripple="false"
          style="width: fit-content; background-color: white;"
          @click="createItem()">
          <span>
            เพิ่มบทความ
          </span>
          <v-icon>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          hide-default-footer>
          <template #[`item.createdAt`]="{ item }">
            {{ item.createdAt | dateUserFormat() }}
          </template>
          <template #[`item.type`]="{ item }">
            <span v-if="item.type === 'news'">
              ข่าวสาร
            </span>
            <span v-else>
              กิจกรรม
            </span>
          </template>
          <template #[`item.actions`]="{ item }">
            <v-menu
              bottom
              left
              offset-y>
              <template #activator="{ on, attrs }">
                <v-btn
                  color="secondary"
                  icon
                  v-bind="attrs"
                  v-on="on">
                  <v-icon>
                    mdi-dots-vertical
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  :ripple="false"
                  @click="detailItem(item)">
                  <v-list-item-title>
                    ดูข้อมูล
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  :ripple="false"
                  @click="editItem(item)">
                  <v-list-item-title>
                    แก้ไข
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  :ripple="false"
                  @click="confirmDialogToggle(item)">
                  <v-list-item-title>
                    ลบ
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <div class="d-flex justify-end">
          <v-pagination
            v-model="query.page"
            :length="totalPages"
            :total-visible="7"
            circle
            color="primary" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import SearchBox from '@/components/SearchBox.vue'
import StatusFilters from '@/components/StatusFilters.vue'
import BlogsProvider from '@/resources/blogs.provider'

const BlogsService = new BlogsProvider()

export default {
  components: {
    SearchBox,
    StatusFilters
  },
  data: () => ({
    loading: false,
    headers: [
      {
        text: 'วันที่สร้าง',
        value: 'createdAt',
        class: 'table-header',
        sortable: true,
        align: 'center',
        width: '150'
      },
      {
        text: 'ชื่อบทความ',
        value: 'name',
        class: 'table-header',
        cellClass: 'limit-text',
        sortable: false
      },
      {
        text: 'ประเภท',
        value: 'type',
        class: 'table-header',
        align: 'center',
        sortable: false
      },
      {
        text: '',
        value: 'actions',
        class: 'table-header',
        sortable: false,
        align: 'right',
        width: '50'
      }
    ],
    types: [
      {
        text: 'ทั้งหมด',
        value: null
      },
      {
        text: 'ข่าวสาร',
        value: 'news'
      },
      {
        text: 'กิจกรรม',
        value: 'event'
      }
    ],
    items: [],
    query: {
      page: 1,
      limit: 10,
      search: '',
      type: null
    },
    totalPages: 1
  }),
  watch: {
    'query.page': {
      handler () {
        this.getItems()
      },
      deep: true
    },
    'query.type': {
      handler () {
        this.getItems(true)
      },
      deep: true
    }
  },
  mounted () {
    this.getItems()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Snackbar/setSnackbar'
    }),
    changeRoute (to) {
      this.$router.push({ name: to })
    },
    async getItems (isClear = false) {
      try {
        this.loading = true

        if (isClear) {
          this.query.page = 1
          this.items = []
        }

        const { data } = await BlogsService.getItems(this.query)

        this.items = data.results
        this.totalPages = data.pages
      } catch (error) {
        console.error('getItems', error)
        this.setSnackbar({
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async onRemove (id) {
      try {
        this.loading = true

        await BlogsService.deleteItemById(id)

        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: 'ลบข้อมูลเสร็จสิ้น',
          type: 'success',
          timeout: 2000
        })

        this.getItems(true)
      } catch (error) {
        console.error('onRemove', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    createItem () {
      this.$router.push({ name: 'BlogCreate' })
    },
    detailItem (item) {
      this.$router.push({
        name: 'BlogDetail',
        params: {
          id: item.id
        }
      })
    },
    editItem (item) {
      this.$router.push({
        name: 'BlogEdit',
        params: {
          id: item.id
        }
      })
    },
    confirmDialogToggle (item) {
      this.$store.dispatch('ConfirmDialog/setDialog', {
        active: true,
        title: 'แจ้งเตือน',
        text: `คุณยืนยันที่จะลบ "${item.name.length > 30 ? `${item.name.slice(0, 30)}...` : item.name}" ใช่หรือไม่?`,
        onConfirm: () => this.onRemove(item.id)
      })
    }
  }
}
</script>

<style scoped>
.items-container {
  width: 100%;
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
</style>
